<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light px-5">
        <a class="navbar-brand" href="#">ATNFT</a>

        <div class="collapse navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <router-link to="/">Collection</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/about">About</router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'Header'
    }
</script>

<style>
    header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .navbar-nav {
        margin-left:30px;
    }

    .nav-item {
        margin-left:10px;
        font-size: 18px;
        vertical-align: middle;
    }

    .nav-item a {
        text-decoration: none;
    }
</style>
<template>
    <footer class='bg-light py-4'>
        <div>
            <router-link to="/">Arnaud Triolet NFT's</router-link>
        </div>
        <div>
            <ul class='mb-0'>
                <li>
                    <router-link to="/contact">Contact</router-link>
                </li>
                <li>
                    <router-link to="/legal-mention">Legal mention</router-link>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
    #app {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
    footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    ul {
        margin-bottom:0;
        list-style: none;
        padding-left: none;
    }
    
    footer a {
        text-decoration: none;
        color: #666;
    }
</style>
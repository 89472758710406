import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Creation from '../views/Creation.vue'
import Contact from '../views/Contact.vue'
import LegalMentions from '../views/LegalMentions.vue'
import About from '../views/About.vue'

const routes = [
  {
    path: '/',
    name: 'Collection',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/legal-mentions',
    name: 'Legal mentions',
    component: LegalMentions
  },
  {
    path: '/creation/:id',
    name: 'Creation',
    component: Creation
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

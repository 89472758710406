import { createApp } from 'vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import App from './App.vue'
import router from './router'
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

let app = createApp(App);

let api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

app.config.globalProperties.$api = api;

app.use(BootstrapVue3)
    .use(router)
    .mount('#app')

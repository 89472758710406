<template>
    <div class="collection">
        <b-row>
            <template v-if="isLoading">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <path fill="none" stroke="#b3c430" stroke-width="8" stroke-dasharray="42.76482137044271 42.76482137044271" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" stroke-linecap="round" style="transform:scale(0.8);transform-origin:50px 50px">
                        <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"></animate>
                    </path>
                </svg>
            </template>
            <template v-else>
                <b-card class='collection-item' :img-src="getBase64Img(creation)">
                    <h1>Snowlog #{{creation.id}}</h1>
                    <p>Original filename : {{ getFileName(creation.log)}}</p>
                </b-card>
            </template>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'CreationShow',
    props: [
        "id"
    ],
    data: function() {
        return {
            isLoading: true,
            creation: null
        }
    },
    mounted: function() {
        var component = this;
        this.$api.get("/api/images/"+this.id).then(function(response){
            component.creation = response.data;
            component.isLoading = false;
        });
    },
    methods: {
        getFileName: function(str) {
            let li = Math.max(str.lastIndexOf('/'), str.lastIndexOf('\\'));
            return new String(str).substring(li + 1);
        },
        getBase64Img: function(crea) {
            return "data:image/png;base64,"+crea.b64;
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.collection-item {
    widows: 100%;
    margin-top: 10px;
}
</style>

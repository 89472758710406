<template>
  <Header />
  <div class="main-container py-5">
    <router-view/>
  </div>
  <Footer />
</template>

<script>
  import Footer from './components/page/Footer.vue'
  import Header from './components/page/Header.vue'

  export default {
    name: 'App',
    components: {
        Header,
        Footer
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.main-container {
  max-width: 1400px;
  margin:auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
}
</style>

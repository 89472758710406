<template>
    <div class="creation">
      <div class="container center">
        <div class="col-md-6 col-sm-12">

          <CreationShow :id="$route.params.id" />
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import CreationShow from '@/components/CreationShow.vue'

export default {
  name: 'Creation',
  components: {
    CreationShow
  }
  // mounted() {
  //   console.log(this.$route.params.id);
  // }
}
</script>

<style>
.container.center {
  justify-content: center;
  display: flex;
}
.card.collection-item {
  padding:0;
}
</style>
